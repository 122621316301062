"use strict";

function addActiveClass(element, target) {
    const clickedElement = document.querySelector(element),
        targetToAddClass = document.querySelector(target);

    clickedElement.addEventListener('click', () => {

        clickedElement.classList.toggle('clicked');
        targetToAddClass.classList.toggle('active');

        if (!targetToAddClass.classList.contains('active')) {
            targetToAddClass.classList.remove('active');
        }
    });
}

function removeActiveClass(element, targetBlock, targetBtn) {
    const clickedElement = document.querySelector(element),
        targetBlockToRemoveClass = document.querySelector(targetBlock),
        targeBtnToRemoveClass = document.querySelector(targetBtn);

    clickedElement.addEventListener('click', () => {

        targeBtnToRemoveClass.classList.remove('clicked');
        targetBlockToRemoveClass.classList.remove('active');
    });
}

function scrollToElement(hrefTo, triggerTo = 0) {
    const element = document.querySelector(hrefTo),
          trigger = document.querySelector(triggerTo);

    if (triggerTo) {
        trigger.click();
    }
    element.scrollIntoView({
        behavior: "smooth"
    });
}

function handleScroll() {
    const scrollToTopBtn = document.querySelector(".scrollToTopBtn");

    let scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight,
        GOLDEN_RATIO = 0.5;

    if ((document.documentElement.scrollTop / scrollableHeight) > GOLDEN_RATIO) {
        //show button
        if (!scrollToTopBtn.classList.contains("showScrollBtn")) {
            scrollToTopBtn.classList.add("showScrollBtn");
        }
    } else {
        //hide button
        if (scrollToTopBtn.classList.contains("showScrollBtn")) {
            scrollToTopBtn.classList.remove("showScrollBtn");
        }
    }

    scrollToTopBtn.addEventListener("click", scrollToTop);
}

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

function stringToHtml(str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return doc.body;
}

function showAlert(messageText, messageType) { // messageType = success, warning etc.
    let html = `
        <div class="toast align-items-center toast-${messageType} border-0 br-8" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true">
            <div class="d-flex toast-inner">
                <div class="toast-body light-text fsz-14">
                    ${messageText}
                </div>
                <button type="button" class="btn-close me-2 mt-2 ms-auto p-2" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `;

    let data = stringToHtml(html),
        alertBox = document.querySelector('.alert-toast'),
        alertItem = data.querySelector('.toast');

    alertBox.append(alertItem);
    alertItem.classList.add('show');

    setTimeout(() => {
        alertItem.remove();
    }, 5000);
}

/*********** Forms ***********/
function forms(formID, state, postUrl, isLogin = 0) {
    const form = document.getElementById(formID),
        inputs = document.querySelectorAll('.form-control');


    inputs.forEach(item => {
        item.addEventListener('input', (e) => {
            const target = e.target;
            target.classList.remove('error_style');
        });
    });

    const message = {
        loading: 'Loading...',
        failure: 'Error'
    };

    const postData = async (url, data) => {
        let res = await fetch(url, {
            method: "POST",
            body: data
        });

        return await res.text();
    };

    const clearInputs = () => {
        inputs.forEach(item => {
            item.value = '';
        });
    };

    
    form.addEventListener('submit', (e) => {
        e.preventDefault();

        const button = form.querySelector('button[type="submit"]');

        let statusMessage = document.createElement('div');
        statusMessage.classList.add('status');
        form.appendChild(statusMessage);

        const formData = new FormData(form);
        if (form.getAttribute('data-form') === "send") {
            for (let key in state) {
                formData.append(key, state[key]);
            }
        }
        
        function preloadButton(action) {
            if (action == "add") {
                button.classList.add('loading');
                button.setAttribute('disabled', '');
            } else {
                button.classList.remove('loading');
                button.removeAttribute('disabled');
            }
        }

        postData(postUrl, formData)
            .then(preloadButton('add'))
            .then(res => {
                const answer = JSON.parse(res);

                if (answer.error && answer.error.field) {
                    Object.entries(answer.error.field).forEach(([key, value]) => {
                        let input = document.getElementsByName(key);
                        input.forEach(item => {
                            item.classList.add('error_style');
                        });
                        preloadButton();
                        showAlert(value, 'danger');
                    });
                } else if (answer.error) {
                    showAlert(answer.error, 'danger');
                } else if (answer.warning) {
                    showAlert(answer.warning, 'warning');
                    preloadButton();
                } else if (answer.success && isLogin) {
                    window.location.href = answer.success;
                } else {
                    preloadButton();
                    showAlert(answer.output, 'success');
                    // button.classList.add('d-none');
                    // statusMessage.classList.add('d-block');
                    // statusMessage.innerHTML = answer.output;
                    clearInputs();
                }
            })
            .catch(() => {
                button.classList.remove('loading');
                button.classList.add('d-none');
                statusMessage.classList.add('d-block');
                statusMessage.textContent = message.failure;
            })
            .finally(() => {
                setTimeout(() => {
                    statusMessage.remove();
                    button.classList.remove('d-none');
                    preloadButton();
                }, 10000);
            });
    });
}

/*********** Button show all ***********/
function showAllItems(octButtonID, octModuleID, itemsLimit, itemsCount) {
    const button = document.getElementById(octButtonID),
          module = document.getElementById(octModuleID),
          items = module.children,
          itemsToShow = [];

    if (itemsCount <= itemsLimit) {
       button.closest('.showmore').classList.add('d-none');
    }

    for (let i = 0; i < itemsCount; i++) {
        if (i < itemsLimit) {
            items[i].classList.add('d-block');
        } else {
            itemsToShow.push(items[i]);
        }
    }

    button.addEventListener('click', (e) => {
        itemsToShow.forEach((item) => {
            item.classList.add('d-block');
        });
        e.target.closest('.showmore').classList.add('d-none');
    });
}

/*********** Modals ***********/
function parseModal(modalID, dataToParse) {
    let parser = new DOMParser();
    let data = parser.parseFromString(dataToParse, 'text/html');

    let modalItem = data.querySelector(modalID).outerHTML;
    let modalHolder = document.querySelector('.modal-holder');
    modalHolder.innerHTML = modalItem;

    let modal = new bootstrap.Modal(modalHolder.querySelector(modalID));
    modal.show();
}

/*********** Modal Login ***********/
function getPopupLogin() {
    fetch('index.php?route=module/oct_popup_login', {
        method: "POST"
    })
    .then(function (response) {
        return response.text();
    })
    .then(function (html) {
        parseModal('#loginModal', html);

        let modalState = {};
        forms('loginModalForm', modalState, 'index.php?route=module/oct_popup_login/login', true);
    })
    .catch(function (err) {
        console.log(err);
    });
}

/*********** Modal Login ***********/
function getPopupFeedback() {
    fetch('index.php?route=module/popup_call_phone', {
            method: "POST"
        })
    .then(function (response) {
        return response.text();
    })
    .then(function (html) {
        parseModal('#callbackModal', html);

        let modalState = {};
        forms('callbackModalForm', modalState, 'index.php?route=module/popup_call_phone/send', true);
    })
    .catch(function (err) {
        console.log(err);
    });
}

/*********** Modal Cart ***********/
function showModalCart() {
    fetch('index.php?route=module/popup_cart', {
        method: "GET",
        headers: {
            "X-Requested-With": "XMLHttpRequest"
        }
    })
    .then(function (response) {
        return response.text();
    })
    .then(function (html) {
        parseModal('#cartModal', html);
    })
    .catch(function (err) {
        console.log(err);
    });
}

function removeCartItem(key) {
    let productKey = key,
        urlToResponse = `index.php?route=module/popup_cart&remove=${productKey}`;

    fetch(urlToResponse, {
            method: "GET"
        })
        .then(function (response) {
            return response.text();
        })
        .then(function (data) {
            let parser = new DOMParser();
            let dataParsed = parser.parseFromString(data, 'text/html');
            const popupCartInner = dataParsed.getElementById('popup-cart-inner').outerHTML;

            updateCart(popupCartInner);
        })
        .catch(function (err) {
            console.log(err);
        });

    function updateCart(parsedData) {
        fetch('index.php?route=module/popup_cart/status_cart', {
                method: "GET",
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const answer = JSON.parse(JSON.stringify(data)),
                      cartTotal = document.getElementById('cart-total'),
                      popupCartInner = document.getElementById('popup-cart-inner');

                cartTotal.innerHTML = answer.total;
                popupCartInner.innerHTML = parsedData;
            })
            .catch(function (err) {
                console.log(err);
            });
    }
}

/*********** Modal Review ***********/
function showReview(id, languageId) {
    const reviewUrl = 'index.php?route=module/oct_product_review/review&review_id=' + id + '&language_id=' + languageId;

    fetch(reviewUrl, {
        method: "GET",
        headers: {
            "X-Requested-With": "XMLHttpRequest"
        }
    })
    .then(function (response) {
        return response.text();
    })
    .then(function (html) {
        parseModal('#reviewModal', html);
    })
    .catch(function (err) {
        console.log(err);
    });
}

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
});

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
});

function showChatWidget() {
    Tawk_API.maximize();
}

window.addEventListener('DOMContentLoaded', () => {

    let currencyForm = document.getElementById('currency'),
        currencyToggle = currencyForm.querySelectorAll('#currency .currency-select'),
        currencyNameInput = currencyForm.querySelector('#currency input[name="code"]');

    currencyToggle.forEach((item) => {
         item.addEventListener('click', (e) => {
             e.preventDefault();
             const target = e.target;
             let nameAttr = target.getAttribute('name');

             currencyNameInput.setAttribute('value', nameAttr);
             currencyForm.submit();
         });
    });

    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach((item) => {
        item.addEventListener('click', (e) => {
            const active = document.querySelector('.faq-item.active');

            e.currentTarget.classList.add('active');

            if (active) {
                active.classList.remove('active');
            }
        });
    });

    const mobileToggleButton = document.getElementById('mobileSidebarToggle');

    mobileToggleButton.addEventListener('click', () => {
        const desktopMenuList = document.getElementById('desktop-menu-list'),
              desktopContacts = document.getElementById('desktop-contacts'),
              desktopCurrency = document.getElementById('desktop-currency'),
              mobileMenuList = document.getElementById('mobile-menu-list'),
              mobileContacts = document.getElementById('mobile-contacts'),
              mobileCurrency = document.getElementById('mobile-currency');

        mobileMenuList.innerHTML = desktopMenuList.innerHTML;
        mobileContacts.innerHTML = desktopContacts.innerHTML;
        mobileCurrency.append(desktopCurrency);
        desktopCurrency.classList.add('sidebar-content-block', 'br-8', 'p-4');

        let mobileMenuListItems = mobileMenuList.querySelectorAll('li'),
            mobileMenuListLinks = mobileMenuList.querySelectorAll('a');

        mobileMenuListItems.forEach((item) => {
            item.classList.remove('list-inline-item');
        });

        mobileMenuListLinks.forEach((item) => {
            item.classList.add('blue-link', 'fsz-14');
        });
    });

    addActiveClass('#mobileSidebarToggle', '#sidebar');
    addActiveClass('#footerInfoBtn', '#footerList');
    removeActiveClass('#sidebarClose', '#sidebar', '#mobileSidebarToggle');
});

document.addEventListener("scroll", () => {
    handleScroll();
});